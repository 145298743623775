import React from 'react';

const useFallbackRef = (givenRef, initialValue) => {
	const fallbackRef = React.useRef(initialValue);

	if (givenRef) {
		return givenRef;
	}

	return fallbackRef;
};

export default useFallbackRef;
