import { ScreenReaderOnly } from '@xo-union/ui-accessibility';
import PropTypes from 'prop-types';
import React from 'react';
import { InclusiveRange } from '../Range';
import { humanTermType, plural, singular } from '../humanTerms';

const ScreenReaderDescription = ({
	id,
	terms,
	visibleSlidesIndexRange,
	totalSlidesCount,
	containerRef,
}) => {
	const humanReadableRange = visibleSlidesIndexRange.map((num) => num + 1);
	const { min, max } = humanReadableRange;

	const announcementTimeout = React.useRef();

	const [shouldAnnounceChanges, setShouldAnnounceChanges] =
		React.useState(false);

	/**
	 * Avoid announcing state of carousels on page load and resize, only do it on change
	 * when focused on an element in the carousel
	 */
	React.useEffect(() => {
		if (containerRef.current.contains(document.activeElement)) {
			setShouldAnnounceChanges(true);
		}
		clearTimeout(announcementTimeout.current);

		announcementTimeout.current = setTimeout(() => {
			setShouldAnnounceChanges(false);
		}, 2000);

		return () => {
			clearTimeout(announcementTimeout.current);
		};
	}, [min, max, containerRef]);

	const style = shouldAnnounceChanges ? undefined : { display: 'none' };
	const countPhrase =
		humanReadableRange.length === 1
			? `${singular(terms.slides)} number ${min}`
			: `${plural(terms.slides)} ${min} to ${max}`;

	return (
		<ScreenReaderOnly
			aria-live="assertive"
			aria-atomic="true"
			style={style}
			id={id}
		>
			{`Showing ${countPhrase} out of ${totalSlidesCount}`}
		</ScreenReaderOnly>
	);
};

ScreenReaderDescription.propTypes = {
	id: PropTypes.string,
	terms: humanTermType(['slides']),
	visibleSlidesIndexRange: PropTypes.instanceOf(InclusiveRange),
	totalSlidesCount: PropTypes.number,
	containerRef: PropTypes.shape({
		current: PropTypes.shape({
			contains: PropTypes.func,
		}),
	}),
};

export default ScreenReaderDescription;
