export default [
	'BDS',
	'BEA',
	'BWP',
	'CAT',
	'DJS',
	'FLO',
	'PLN',
	'REC',
	'VID',
	'WCK',
	'WPH',
];
