const getDefaultAlignment = ({
	currentIndex,
	minIndex,
	maxIndex,
	hasSlideOverflow,
}) => {
	if (!hasSlideOverflow) {
		// There aren't enough slides
		return 'center';
	}

	if (currentIndex === maxIndex) {
		return 'right';
	}

	if (currentIndex === minIndex) {
		return 'left';
	}

	return 'center';
};

export default getDefaultAlignment;
