import React from 'react';
import useRenderRef from '../useRenderRef';

const useReducerWithChangeCallback = (
	reducer,
	initialState,
	handleChange,
	dependencies = [],
) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);

	// Use a ref for state to avoid needing to re-create dispatchWrapper when state changes
	// Re-creating dispatch wrapper causes infinite loops when dispatching an action
	// Inside of an effect
	const stateRef = useRenderRef(state);

	const dispatchWrapper = React.useCallback(
		(action) => {
			const currentState = stateRef.current;
			const nextState = reducer(currentState, action);

			if (nextState !== currentState) {
				handleChange(currentState, nextState, action);
			}

			dispatch(action);
		},
		[reducer, dispatch, ...dependencies],
	);

	return [state, dispatchWrapper];
};

export default useReducerWithChangeCallback;
