import React from 'react';

const useActionCreators = (creators, dispatch) =>
	React.useMemo(
		() =>
			Object.fromEntries(
				Object.entries(creators).map(([name, creator]) => [
					name,
					(...params) => dispatch(creator(...params)),
				]),
			),
		[dispatch, creators],
	);

export default useActionCreators;
