import React from 'react';
export const FACET_REGEX =
	/(redirectToCity=false|sort=featured|sort=distance|sort=review-rating)/g;

export default (faqData, title, pathname) => {
	const date = new Date(Date.now());
	const obj = {
		'@context': 'http://schema.org/',
		'@type': 'FAQPage',
		name: 'The Knot Worldwide',
		description: title,
		url: pathname,
		dateCreated: date.toISOString(),
		mainEntity: [],
	};

	for (let i = 0; i < faqData.length; i += 1) {
		obj.mainEntity.push({
			'@type': 'Question',
			name: faqData[i].question,
			acceptedAnswer: {
				'@type': 'Answer',
				text: faqData[i].answer,
			},
		});
	}

	return (
		<script id="faqLdJson" type="application/ld+json">
			{JSON.stringify(obj)}
		</script>
	);
};
