import classesPropType from '@xo-union/classes-prop-type';
import { useClasses } from '@xo-union/react-css-modules';
import { useUniqueID } from '@xo-union/react-unique-id';
import { FocusBoundary } from '@xo-union/ui-accessibility';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useRef, useMemo } from 'react';

import ScreenReaderDescription from '../ScreenReaderDescription';
import { humanTermType, singular } from '../humanTerms';
import useNavigation from '../useNavigation';

import useFallbackRef from '../useFallbackRef';
import styles from './styles.scss';

/**
 * Carousel container.
 */
const BaseCarousel = (props) => {
	const {
		renderNextButton: NextButton,
		renderPrevButton: PrevButton,
		classes: classesProp,
		id,
		srDescriptionID,
		slideIDPrefix: slideIDPrefixProp,
		terms,
		children,
		slideRole,
		carouselRole,

		// navigation props
		navigationIndex,
		navigationIndexDefaultValue,
		onNavigationIndexChange,
		onNavigationRangeChange,
		pagination,

		gutter,
		internals: {
			containerRef: givenContainerRef,
			totalSlidesCount,
			visibleSlidesCount,
			isReadyToRender,
			slideWidthValue,
		},
	} = props;

	const containerRef = useFallbackRef(givenContainerRef);

	const hasSlideOverflow = totalSlidesCount > visibleSlidesCount;
	const slidesListRef = useRef(null);
	const classes = useClasses(styles, classesProp);

	const slideIDPrefix = useUniqueID(slideIDPrefixProp);
	const slideContainerID = useUniqueID(id);
	const screenReaderDescriptionID = useUniqueID(srDescriptionID);

	const navigationProps = {
		navigationIndex,
		navigationIndexDefaultValue,
		onNavigationIndexChange,
		onNavigationRangeChange,
		pagination,
		slidesListRef,
		gutter,
	};

	const navigation = useNavigation(navigationProps, {
		visibleSlidesCount,
		totalSlidesCount,
	});

	const onScroll = () => {
		if (slidesListRef.current === null) {
			return;
		}
		const rawPosition =
			slidesListRef.current.scrollLeft /
			(slidesListRef.current.clientWidth + gutter);
		navigation.position(rawPosition);
	};

	const halfGutter = `${gutter ? gutter / 2 : 0}px`;

	const keydown = (event) => {
		switch (event.key) {
			case 'ArrowLeft': {
				navigation.moveLeft();
				event.preventDefault();
				break;
			}
			case 'ArrowRight': {
				navigation.moveRight();
				event.preventDefault();
				break;
			}
			default: {
				break;
			}
		}
	};

	return useMemo(() => {
		return (
			<div
				style={{
					'--carousel-half-gutter': halfGutter,
					'--carousel-slide-width': slideWidthValue,
				}}
				className={cx(classes.relativeContainer, {
					[classes.notReady]: !isReadyToRender,
				})}
				onKeyDown={keydown}
			>
				<div
					ref={containerRef}
					className={classes.container}
					role={carouselRole}
					id={slideContainerID}
					aria-roledescription="carousel"
					aria-label={singular(terms.carousel)}
					aria-describedby={screenReaderDescriptionID}
				>
					<ScreenReaderDescription
						id={screenReaderDescriptionID}
						containerRef={containerRef}
						visibleSlidesIndexRange={navigation.visibleSlidesIndexRange}
						terms={terms}
						totalSlidesCount={totalSlidesCount}
					/>

					<div className={classes.leftButtonContainer}>
						<PrevButton
							aria-controls={`${slideContainerID} ${screenReaderDescriptionID}`}
							aria-label={`See previous ${singular(terms.carousel)} page`}
							className={cx(
								'prevButton' in classes && classes.prevButton,
								!navigation.canMove(-1) && classes.hideButton,
							)}
							disabled={!navigation.canMove(-1)}
							onClick={navigation.moveLeft}
							onKeyDown={keydown}
						/>
					</div>

					<ul
						ref={slidesListRef}
						onScroll={onScroll}
						className={classes.slidesList}
					>
						{React.Children.map(children, (child, i) => {
							const isVisible = navigation.visibleSlidesIndexRange.includes(i);

							return (
								<FocusBoundary enabled={isVisible}>
									<li
										aria-hidden={isVisible ? undefined : true}
										className={classes.slideContainer}
									>
										<div
											id={[slideIDPrefix, i].join('--')}
											role={slideRole}
											aria-roledescription="slide"
											aria-label={singular(terms.slides)}
											className={classes.slide}
										>
											{child}
										</div>
									</li>
								</FocusBoundary>
							);
						})}
					</ul>

					<div className={classes.rightButtonContainer}>
						<NextButton
							aria-controls={`${slideContainerID} ${screenReaderDescriptionID}`}
							aria-label={`See next ${singular(terms.carousel)} page`}
							className={cx(
								'nextButton' in classes && classes.nextButton,
								!navigation.canMove(1) && classes.hideButton,
							)}
							disabled={!navigation.canMove(1)}
							onKeyPress={keydown}
							onClick={navigation.moveRight}
							onKeyDown={keydown}
						/>
					</div>
				</div>
			</div>
		);
	}, [navigation.committedNavigationIndex]);
};

BaseCarousel.propTypes = {
	gutter: PropTypes.number,
	internals: PropTypes.shape({
		containerRef: PropTypes.shape({
			current: PropTypes.shape({}),
		}),
		totalSlidesCount: PropTypes.number,
		isReadyToRender: PropTypes.bool,
		slideWidthValue: PropTypes.string,
		visibleSlidesCount: PropTypes.number,
		extraPixels: PropTypes.number,
	}),

	/**
	 * Render the previous button
	 */
	renderPrevButton: PropTypes.func,

	/**
	 * Render the next button
	 */
	renderNextButton: PropTypes.func,

	/**
	 * Slides. Each child is wrapped in a slide container.
	 */
	children: PropTypes.node,

	/**
	 * Classes
	 */
	classes: classesPropType([
		'relativeContainer',
		'container',
		'slide',
		'slideContainer',
		'slidesList',
		'prevButton',
		'nextButton',
	]),
	/**
	 * Override carousel container id
	 */
	id: PropTypes.string,
	/**
	 * Override ID of screen reader description element
	 */
	srDescriptionID: PropTypes.string,
	/**
	 * Human terms used to descrive the carousel and slides. This is to provide a better screen reader experience
	 *
	 * Examples:
	 * ```json
	 *  {
	 *     "carousel": "Product catalog",
	 *     "slides": {"plural": "Products", "singular": "Product"}
	 *  }
	 * ```
	 */
	terms: humanTermType(['slides', 'carousel']),

	/**
	 * Aria role to assign to slide. "group" is the default, and should be used in most general cases. "tabpanel" should be used when every other slide is not visible, additionally, if there is are tabs used to control the slides. See example here:
	 *
	 *  https://w3c.github.io/aria-practices/examples/carousel/carousel-2-tablist.html
	 */
	slideRole: PropTypes.oneOf(['group', 'tabpanel']),
	/**
	 * Aria role to assign to carousel. "group" is the default. This should be used when multiple instances of the carousel will be rendered on the page. However, you can/should use "region" when the carousel takes up a significant amount of space on the page, or when you have a single carousel on the page and want to identify it as a major landmark.
	 */
	carouselRole: PropTypes.oneOf(['group', 'region']),
	/**
	 * Override prefix used for each slide id
	 */
	slideIDPrefix: PropTypes.string,
	/**
	 * Page size used to determine how many slides to move on left and right button clicks. When set to `'auto'` it is dynamically adjusted to be the number of visible slides
	 */
	pagination: PropTypes.oneOfType([
		PropTypes.oneOf(['auto']),
		PropTypes.number,
	]),

	/**
	 * Callback called before the navigation index changes
	 *
	 * This can be due to user dragging the slides, clicking on the navigation buttons,
	 * or even resizing the screen.
	 */
	onNavigationIndexChange: PropTypes.func,

	/**
	 * Callback called after carousel determines the navigation bounds/range of the carousel also
	 * known as the min and max index allowed, while respecting the visual requirements of the carousel
	 * (not allowing empty spaces on the left or right side of the carousel)
	 */
	onNavigationRangeChange: PropTypes.func,

	/**
	 * Number to control the navigation index
	 */
	navigationIndex: PropTypes.number,

	/**
	 * The initial value for the navigation index on uncontrolled mode
	 */
	navigationIndexDefaultValue: PropTypes.number,
};

BaseCarousel.defaultProps = {
	slideRole: 'group',
	carouselRole: 'group',
	renderPrevButton: () => null,
	renderNextButton: () => null,
	terms: {
		slides: {
			plural: 'slides',
			singular: 'slide',
		},
		carousel: 'carousel',
	},
};

export default BaseCarousel;
