import React from 'react';

const $min = Symbol('min');
const $max = Symbol('max');

export class InclusiveRange {
	constructor(min, max) {
		if (typeof min !== 'number' || typeof max !== 'number') {
			throw TypeError('Expected min and max to be numbers');
		}

		if (min > max) {
			throw TypeError('Expected min to be less than or equal to max');
		}

		if (!Number.isInteger(min) || !Number.isInteger(max)) {
			throw TypeError('Expected min and max to be integers');
		}

		this[$min] = min;
		this[$max] = max;
	}

	get min() {
		return this[$min];
	}

	get max() {
		return this[$max];
	}

	map(mapper) {
		return new this.constructor(mapper(this[$min]), mapper(this[$max]));
	}

	includes(number) {
		return number >= this.min && number <= this.max;
	}

	get length() {
		return Math.max(this.max - this.min + 1, 0);
	}

	restrict(number) {
		return Math.min(
			this.max,

			Math.max(
				this.min,

				number,
			),
		);
	}
}

export class ExclusiveRange extends InclusiveRange {
	get min() {
		return this[$min] + 1;
	}

	get max() {
		return this[$max] - 1;
	}
}

export const useInclusiveRange = (min, max) =>
	React.useMemo(() => new InclusiveRange(min, max), [min, max]);
