import React from 'react';
import { useInclusiveRange } from '../Range';
import useActionCreators from '../useActionCreators';
import useReducerWithChangeCallback from '../useReducerWithChangeCallback';
import useRenderRef from '../useRenderRef';

const actionTypes = {
	POSITION: 'POSITION',
};

const actionCreators = {
	position: (position, navigationIndexRange) => ({
		type: actionTypes.POSITION,
		position,
		navigationIndexRange,
	}),
};

const getInitialState = (initialIndex = 0) => ({
	committedNavigationIndex: initialIndex,
});

const reducer = (state, action) => {
	switch (action.type) {
		case actionTypes.POSITION: {
			const nextIndex = action.position;

			if (nextIndex === state.committedNavigationIndex) {
				return state;
			}

			return {
				...state,
				committedNavigationIndex: nextIndex,
			};
		}

		default: {
			return state;
		}
	}
};

const useNavigation = (props, { totalSlidesCount, visibleSlidesCount }) => {
	const isControlled = React.useRef(
		typeof props.navigationIndex !== 'undefined',
	);
	const visualOffset = React.useRef(props.navigationIndex);

	const initialState = isControlled.current
		? getInitialState(props.navigationIndex)
		: getInitialState(props.navigationIndexDefaultValue);

	/**
	 * Use a ref to call the onNavigationChange callback in `useEffect`. This is helpful to ensure the
	 * effect always has the latest version of the callback, without triggering the effect every time
	 * the callback changes.
	 */
	const onNavigationIndexChangeRef = useRenderRef(
		props.onNavigationIndexChange,
	);
	const onNavigationRangeChangeRef = useRenderRef(
		props.onNavigationRangeChange,
	);

	const onStateChange = React.useCallback((currentState, nextState) => {
		if (
			nextState.committedNavigationIndex !==
			currentState.committedNavigationIndex
		) {
			onNavigationIndexChangeRef.current(
				nextState.committedNavigationIndex,
				currentState.committedNavigationIndex,
			);
		}
	}, []);

	const [state, dispatch] = useReducerWithChangeCallback(
		reducer,
		initialState,
		onStateChange,
	);

	const actions = useActionCreators(actionCreators, dispatch);

	const visibleSlidesIndexRange = useInclusiveRange(
		state.committedNavigationIndex,
		state.committedNavigationIndex + Math.max(visibleSlidesCount - 1, 0),
	);

	const pageSize =
		props.pagination === 'auto'
			? visibleSlidesIndexRange.length
			: props.pagination;

	const navigationIndexRange = useInclusiveRange(
		0,
		totalSlidesCount - visibleSlidesCount,
	);

	/**
	 * Side-effects
	 */

	React.useEffect(() => {
		onNavigationRangeChangeRef.current(navigationIndexRange);
	}, [navigationIndexRange]);

	return React.useMemo(() => {
		const move = (offset) => {
			const slideContainer = props.slidesListRef.current;
			const slideWidth = slideContainer.clientWidth + props.gutter;
			const scrollLeft =
				(visualOffset.current + offset) * slideWidth + props.gutter / 2 - 1;

			// the scrollTo 'smooth' behavior will trigger an 'onScroll' event, same as finger drag
			slideContainer?.scrollTo({
				left: scrollLeft,
				top: 0,
				behavior: 'smooth',
			});
			// track  eventual visual offset after smooth scroll stops
			visualOffset.current = navigationIndexRange.restrict(
				visualOffset.current + offset,
			);
		};

		const moveLeft = () => {
			move(-pageSize);
		};

		const moveRight = () => {
			move(pageSize);
		};

		const position = (position) => {
			actions.position(parseInt(position + 0.5, 10), navigationIndexRange);
		};

		const canMove = (offset) => {
			const nextIndex = visualOffset.current + offset;
			return navigationIndexRange.includes(nextIndex);
		};

		return {
			// actions
			position,
			move,
			moveLeft,
			moveRight,

			// state
			committedNavigationIndex: state.committedNavigationIndex,
			visibleSlidesIndexRange,
			navigationIndexRange,

			// selectors
			canMove,
		};
	}, [actions, state, pageSize, navigationIndexRange, visibleSlidesIndexRange]);
};

export default useNavigation;
