import React from 'react';

/**
 * Creates a ref that is always in sync with a rendered value
 *
 * This is helpful when needing to refer to a prop value without forcing
 * re-creation of cached react values or re-runs of react effects
 */
const useRenderRef = (value) => {
	const ref = React.useRef(value);
	ref.current = value;
	return ref;
};

export default useRenderRef;
