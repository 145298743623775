import classesPropType from '@xo-union/classes-prop-type';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React from 'react';

import BaseCarousel from '../BaseCarousel';
import getDefaultAlignment from '../getDefaultAlignment';
import getSlideCount from '../getSlideCount';
import { humanTermType } from '../humanTerms';

/**
 * An alternate implementation of carousel that renders every slide as fluid block containers (taking up 100% of the available space depending on the number of desired visible slots). This implementation has better render performance than the default Carousel because it only uses CSS to render slides at the appropriate size and it doesn't depend on JS to load for the first render (hence has better SSR performance and is ideal for content above the fold).
 */
const CarouselWithStaticVisibleSlots = (props) => {
	const {
		children,
		visibleSlots: visibleSlideSlots,

		...restProps
	} = props;

	const totalSlidesCount = getSlideCount(children);
	const visibleSlidesCount = Math.min(visibleSlideSlots, totalSlidesCount);
	const slideWidthPercent = 100 / visibleSlidesCount;
	const slideWidthValue = `${slideWidthPercent}%`;
	const isReadyToRender = true;
	const extraPixels = 0;

	return (
		<BaseCarousel
			{...restProps}
			internals={{
				totalSlidesCount,
				slideWidthValue,
				isReadyToRender,
				visibleSlidesCount,
				extraPixels,
			}}
		>
			{children}
		</BaseCarousel>
	);
};

CarouselWithStaticVisibleSlots.propTypes = {
	/**
	 * Number of visible slots.
	 *
	 * For best render performance, this number should not depend on a browser state (i.e. result of matchMedia for responsiveness)
	 */
	visibleSlots: PropTypes.number,
	/**
	 * A mapper function that receives the navigation state and returns 'left', 'center', 'right'
	 */
	alignment: PropTypes.func,
	/**
	 * Render the previous button
	 */
	renderPrevButton: PropTypes.func,

	/**
	 * Render the next button
	 */
	renderNextButton: PropTypes.func,

	/**
	 * Slides. Each child is wrapped in a slide container.
	 */
	children: PropTypes.node,

	/**
	 * Classes
	 */
	classes: classesPropType([
		'relativeContainer',
		'container',
		'slide',
		'slideContainer',
		'slidesList',
		'prevButton',
		'nextButton',
		'isLeftAligned',
		'isRightAligned',
		'isCenterAligned',
	]),
	/**
	 * Gutter between slides
	 */
	gutter: PropTypes.number.isRequired,
	/**
	 * Override carousel container id
	 */
	id: PropTypes.string,
	/**
	 * Override ID of screen reader description element
	 */
	srDescriptionID: PropTypes.string,
	/**
	 * Human terms used to descrive the carousel and slides. This is to provide a better screen reader experience
	 *
	 * Examples:
	 * ```json
	 *  {
	 *     "carousel": "Product catalog",
	 *     "slides": {"plural": "Products", "singular": "Product"}
	 *  }
	 * ```
	 */
	terms: humanTermType(['slides', 'carousel']),

	/**
	 * Callback called before the navigation index changes
	 *
	 * This can be due to user dragging the slides, clicking on the navigation buttons,
	 * or even resizing the screen.
	 */
	onNavigationIndexChange: PropTypes.func,

	/**
	 * Callback called after carousel determines the navigation bounds/range of the carousel also
	 * known as the min and max index allowed, while respecting the visual requirements of the carousel
	 * (not allowing empty spaces on the left or right side of the carousel)
	 */
	onNavigationRangeChange: PropTypes.func,

	/**
	 * Number to control the navigation index
	 */
	navigationIndex: PropTypes.number,

	/**
	 * The initial value for the navigation index on uncontrolled mode
	 */
	navigationIndexDefaultValue: PropTypes.number,

	/**
	 * Aria role to assign to slide. "group" is the default, and should be used in most general cases. "tabpanel" should be used when every other slide is not visible, additionally, if there is are tabs used to control the slides. See example here:
	 *
	 *  https://w3c.github.io/aria-practices/examples/carousel/carousel-2-tablist.html
	 */
	slideRole: PropTypes.oneOf(['group', 'tabpanel']),
	/**
	 * Aria role to assign to carousel. "group" is the default. This should be used when multiple instances of the carousel will be rendered on the page. However, you can/should use "region" when the carousel takes up a significant amount of space on the page, or when you have a single carousel on the page and want to identify it as a major landmark.
	 */
	carouselRole: PropTypes.oneOf(['group', 'region']),
	/**
	 * Override prefix used for each slide id
	 */
	slideIDPrefix: PropTypes.string,
	/**
	 * Page size used to determine how many slides to move on left and right button clicks. When set to `'auto'` it is dynamically adjusted to be the number of visible slides
	 */
	pagination: PropTypes.oneOfType([
		PropTypes.oneOf(['auto']),
		PropTypes.number,
	]),
};

CarouselWithStaticVisibleSlots.defaultProps = {
	pagination: 1,
	visibleSlots: 1,
	slideRole: 'group',
	carouselRole: 'group',
	alignment: getDefaultAlignment,
	renderPrevButton: () => null,
	renderNextButton: () => null,
	terms: {
		slides: {
			plural: 'slides',
			singular: 'slide',
		},
		carousel: 'carousel',
	},
	onNavigationIndexChange: noop,
	onNavigationRangeChange: noop,
};

export default CarouselWithStaticVisibleSlots;
