import PropTypes from 'prop-types';

export const plural = (term) => {
	if (typeof term === 'string') {
		return term;
	}

	const value = term.plural;

	if (!value) {
		throw new Error(
			'Expected term to have a plural variation. Use a string or an object with a property named `plural`',
		);
	}

	return value;
};

export const singular = (term) => {
	if (typeof term === 'string') {
		return term;
	}

	const value = term.singular;

	if (!value) {
		throw new Error(
			'Expected term to have a singular variation. Use a string or an object with a property named `singular`',
		);
	}

	return value;
};

export const humanTermType = (keys) =>
	PropTypes.shape(
		Object.fromEntries(
			keys.map((key) => [
				key,
				PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.shape({
						singular: PropTypes.string,
						plural: PropTypes.string,
					}),
				]),
			]),
		),
	);
